import React from 'react'
import { Head } from '@components'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { LazyImageFull, ImageState } from 'react-lazy-images'
import * as st from '@assets/styl/enterprises.module.styl'
import { useLanguage } from '../../hooks'
import { translatePath } from '../../translations'

type Props = {
  facades: Array<string>
  name: string
  neighborhood: string
  city: string
  tag: string
  title: string
  description: string
  room: string
  area: string
  garage: string
  url: string
  images: Array<{
    url: string
  }>
}

const Item = ({
  facades,
  name,
  neighborhood,
  city,
  tag,
  title,
  description,
  room,
  area,
  garage,
  url,
  images,
}: Props) => {
  const { t } = useLanguage()

  return (
    <li className={st.enterprise}>
      <LazyImageFull src={facades[1]}>
        {({ imageState, ref }) => (
          <div
            ref={ref}
            style={{
              backgroundImage: `url(${
                imageState === ImageState.LoadSuccess ? facades[1] : ''
              })`,
            }}
          ></div>
        )}
      </LazyImageFull>
      <div>
        <div className={st.content}>
          <div>
            <div>
              <h4>{name}</h4>
              <p>
                {neighborhood} / {city}
              </p>
              {Boolean(tag) && <span className={st.tag}>{tag}</span>}
            </div>
          </div>
          <div>
            {title && <h5>{title}</h5>}
            {description && <p>{description}</p>}
            {Boolean(room && area && garage) && (
              <div>
                <ul>
                  <li className="icon-menubar-link">{room}</li>
                  <li className="icon-menubar-link">{area}</li>
                  <li className="icon-menubar-link">{garage}</li>
                </ul>
              </div>
            )}
            <a className="btn light" href={url} target="_blank">
              {t('Releases.visitWebsite')}
            </a>
          </div>
        </div>
        {images[0] && (
          <LazyImageFull src={images[0].url}>
            {({ imageState, ref }) => (
              <div
                ref={ref}
                style={{
                  backgroundImage: `url(${
                    imageState === ImageState.LoadSuccess ? images[0].url : ''
                  })`,
                }}
              ></div>
            )}
          </LazyImageFull>
        )}
      </div>
    </li>
  )
}

export default () => {
  const items: Array<Props> = useStaticQuery(graphql`
    query {
      allReleases {
        nodes {
          facades
          name
          neighborhood
          city
          tag
          title
          description
          room
          area
          garage
          url
          feature
          images {
            url
          }
        }
      }
    }
  `).allReleases.nodes

  const { t } = useLanguage()

  return (
    <>
      <Head
        pathname={translatePath('/empreendimentos/lancamentos/')}
        title={t('Releases.titlePage')}
        description={t('Releases.descriptionPage')}
      />

      <section className={st.hero}>
        <h1>
          {t('Releases.title.1')} <b>{t('Releases.title.2')}</b>
        </h1>
      </section>

      <section className={st.core}>
        <div>
          <h3>{t('Releases.subtitle')}</h3>
          <ul>
            {items.map((item, key) => (
              <Item key={key} {...item} />
            ))}
          </ul>
        </div>
      </section>

      <section className={st.core}>
        <div>
          <h3>{t('Releases.subtitle1')}</h3>
          <div className={st.links}>
            <Link
              to={translatePath('/empreendimentos/entregues/')}
              className="btn"
            >
              {t('Releases.button.1')}
            </Link>
            <a
              href="https://usados.dimasconstrucoes.com.br"
              target="_blank"
              className="btn"
            >
              {t('Releases.button.2')}
            </a>
          </div>
        </div>
      </section>
    </>
  )
}
